.about {
    padding: 100px 90px;
    width: 100%;
    height: 100%;
}

.about__title {
    font-family: "Neoneon", sans-serif;
    font-weight: 400;
    font-size: 64px;
    text-transform: uppercase;
    color: #fff;
    line-height: 100%;
    text-shadow: 0 0 7px #fff,
        0 0 10px #fff,
        0 0 21px #fff,
        0 0 42px rgb(4, 123, 233),
        0 0 82px rgb(4, 123, 233),
        0 0 92px rgb(4, 123, 233),
        0 0 102px rgb(4, 123, 233),
        0 0 151px rgb(4, 123, 233);
}

.about__subtitle {
    margin-top: 25px;
    font-weight: 300;
    font-size: 28px;
    color: #fff;
}

.about__desc {
    margin-top: 35px;
    max-width: 574px;
    margin-left: auto;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #fff;
}

.about__foto {
    position: absolute;
    bottom: 0;
    left: 0;
}

@media (max-width: 1440px) {
    .about {
        padding: calc((100vw - 320px)/(1440 - 320) * (100 - 30) + 30px);
        padding-top: calc((100vw - 320px)/(1440 - 320) * (100 - 60) + 60px);
    }

    .about__title {
        font-size: calc((100vw - 320px)/(1440 - 320) * (64 - 29) + 29px);
    }

    .about__subtitle {
        font-size: calc((100vw - 320px)/(1440 - 320) * (28 - 20) + 20px);
    }

    .about__desc {
        margin-top: calc((100vw - 320px)/(1440 - 320) * (35 - 10) + 10px);
        max-width: 574px;
        font-size: calc((100vw - 320px)/(1440 - 320) * (20 - 14) + 14px);
    }

    .about__foto {
        width: calc((100vw - 320px)/(1440 - 320) * (294 - 100) + 100px);

    }

}

@media (max-width: 300px) {
    .about__foto {
        display: none;

    }
}