@font-face {
  font-family: 'Neoneon';
  src: url(../../fonts/Neoneon/Neoneon.ttf) format('ttf'),
    url(../../fonts/Neoneon/Neoneon.woff) format('woff'),
    url(../../fonts/Neoneon/Neoneon.otf) format('otf');
  font-weight: 400;
}

.app {
  position: relative;
  background-image: url(./img/bg.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  width: 100%;
  height: 100vh;
  padding: 45px;
}

.app__wrapper {
  max-width: 1360px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  height: 100%;
  gap: 60px;

}

.app__desc {
  max-width: 1009px;
  width: 100%;
  max-height: 100vh;
  height: 100%;
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blur {
  background: linear-gradient(135.00deg, rgba(225, 202, 255, 0.05) 0%, rgba(56, 48, 227, 0.05) 100%);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
  border: 1px solid rgba(191, 148, 246, 0.2);
}

.burger {
  position: absolute;
  top: 40px;
  right: 40px;
  font-family: 'Neoneon';
  font-size: 25px;
  font-weight: 400;
  z-index: 2;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  display: none;
}

.burger__open {
  text-shadow: 0 0 7px #fff,
    0 0 12px rgb(123, 255, 0),
    0 0 22px rgb(123, 255, 0),
    0 0 42px rgb(123, 255, 0),
    0 0 62px rgb(123, 255, 0),
    0 0 82px rgb(123, 255, 0);
}

.burger__close {
  text-shadow: 0 0 7px #fff,
    0 0 12px rgb(255, 0, 0),
    0 0 22px rgb(123, 0, 0),
    0 0 42px rgb(123, 0, 0),
    0 0 62px rgb(123, 0, 0),
    0 0 82px rgb(123, 0, 0);
}

@media (max-width: 1440px) {
  .app {
    padding: calc((100vw - 320px)/(1440 - 320) * (45 - 15) + 15px);
  }

  .app__wrapper {
    gap: calc((100vw - 320px)/(1440 - 320) * (60 - 15) + 15px);
  }

}

@media (max-width: 648px) {
  .burger {
    display: block;
  }
}