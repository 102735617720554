.promo {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    padding: 20px;
}

.promo__title {
    color: rgb(255, 255, 255);
    font-family: 'Neoneon';
    font-size: 120px;
    font-weight: 400;
    text-align: left;
    line-height: 100%;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 7px #fff,
        0 0 10px #fff,
        0 0 21px #fff,
        0 0 42px rgb(4, 123, 233),
        0 0 82px rgb(4, 123, 233),
        0 0 92px rgb(4, 123, 233),
        0 0 102px rgb(4, 123, 233),
        0 0 151px rgb(4, 123, 233);

    animation: flicker 1.5s infinite alternate;
}

@keyframes flicker {

    0%,
    18%,
    22%,
    25%,
    53%,
    57%,
    100% {

        text-shadow:
            0 0 4px #fff,
            0 0 11px #fff,
            0 0 19px #fff,
            0 0 40px rgb(4, 123, 233),
            0 0 80px rgb(4, 123, 233),
            0 0 90px rgb(4, 123, 233),
            0 0 100px rgb(4, 123, 233),
            0 0 150px rgb(4, 123, 233);

    }

    20%,
    24%,
    55% {
        text-shadow:
            0 0 4px #ffffff00,
            0 0 11px #ffffff00,
            0 0 19px #ffffff00,
            0 0 40px rgba(4, 122, 233, 0),
            0 0 80px rgba(4, 122, 233, 0),
            0 0 90px rgba(4, 122, 233, 0),
            0 0 100px rgba(4, 122, 233, 0),
            0 0 150px rgba(4, 122, 233, 0);
    }
}

.promo__btns {
    display: flex;
    gap: 46px;
    flex-wrap: wrap;
}

.promo__btns a,
.promo__btns button {
    border-radius: 50px;
    box-shadow: inset 10px 10px 20px 0px rgba(9, 63, 255, 0.49);
    background: rgb(141, 168, 0);
    padding: 15px 30px;
    width: 200px;
    border: none;
    cursor: pointer;
    color: rgb(255, 255, 255);
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.75px;
    text-align: center;
    text-transform: uppercase;
    transition: box-shadow 0.3s;
}

.promo__btns .promo__btns-work {
    box-shadow: inset 10px 10px 20px 0 #8da800;
    background: #19191b;
}

.promo__btns-contact:hover {
    box-shadow: inset 10px 10px 20px 0px rgba(9, 63, 255, 0.49),
        0 0 7px #fff,
        0 0 10px #fff,
        0 0 21px #fff;
}

.promo__btns-work:hover {
    box-shadow: inset 10px 10px 20px 0 #8da800,
        0 0 7px #fff,
        0 0 10px #fff,
        0 0 21px #fff;
}

@media (max-width: 1440px) {

    .promo__title {
        font-size: calc((100vw - 320px)/(1440 - 320) * (120 - 51) + 51px);
    }

    .promo__btns {
        gap: calc((100vw - 320px)/(1440 - 320) * (46 - 20) + 20px);
    }

    .promo__btns a,
    .promo__btns button {
        border-radius: 50px;
        padding: 15px 30px;
        font-size: 20px;
    }

}

@media (max-width: 883px) {

    .promo__btns a,
    .promo__btns button {
        width: 100%;
    }
}