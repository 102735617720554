.eshop {
    width: 100%;
    height: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
}

.eshop__title {
    font-family: "Neoneon", sans-serif;
    font-weight: 400;
    font-size: 64px;
    text-transform: uppercase;
    color: #fff;
    line-height: 100%;
    text-shadow: 0 0 7px #1400ff,
        0 0 10px #1400ff,
        0 0 21px #1400ff,
        0 0 42px rgb(4, 123, 233),
        0 0 82px rgb(4, 123, 233),
        0 0 92px rgb(4, 123, 233),
        0 0 102px rgb(4, 123, 233),
        0 0 151px rgb(4, 123, 233);
}

.eshop__cart {
    border: 1px solid rgba(191, 148, 246, 0.0);
    padding: 10px;
    border-radius: 10px;
    transition: all 0.7s;
    font-size: 0;
}

.eshop__cart:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.8);
    border: 1px solid rgba(191, 148, 246, 0.2);
}

.eshop__cart img {
    max-width: 400px;
    border-radius: 10px;
}

.eshop__wrapper {
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    height: 100%;
}

@media (max-width: 1440px) {
    .eshop {
        padding: calc((100vw - 320px)/(1440 - 320) * (40 - 15) + 15px);
    }

    .eshop__title {
        font-size: calc((100vw - 320px)/(1440 - 320) * (64 - 29) + 29px);
    }

    .eshop__cart img {
        max-width: calc((100vw - 320px)/(1440 - 320) * (345 - 210) + 210px);
    }
}

@media (max-width: 1024px) {
    .eshop__cart img {
        max-width: calc((100vw - 320px)/(1024 - 320) * (310 - 210) + 210px);
    }
}

@media (max-width: 648px) {
    .eshop {
        padding-top: 65px;
    }
}