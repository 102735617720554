.nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    transition: all 0.5s;
}

.nav__logo path {
    transition: all 0.3s;
}

.nav__logo:hover path {
    fill: #ffffff;
    filter: drop-shadow(0px 0px 2px rgb(255, 255, 255)) drop-shadow(0px 0px 2px rgb(255, 255, 255));
}

.nav__ul {
    margin-top: 81px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.nav__ul-link {
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    transition: text-shadow 0.3s;
}

.nav__ul-link:hover {
    text-shadow: 0 0 7px #fff,
        0 0 10px #fff,
        0 0 21px #fff;
}

.nav__contact-tel {
    font-weight: 400;
    font-size: 24px;
    transition: text-shadow 0.3s;
}

.nav__contact-tel:hover {
    text-shadow: 0 0 7px #fff,
        0 0 10px #fff,
        0 0 21px #fff;
}

.nav__contact-social {
    margin-top: 40px;
    display: flex;
    gap: 20px;
    padding-bottom: 48px;
}

.nav__contact-social a {
    border-radius: 12px;
    transition: box-shadow 0.3s;
}

.nav__contact-social a:hover {
    box-shadow: 0 0 7px #fff,
        0 0 10px #fff,
        0 0 21px #fff;
}

@media (max-width: 648px) {
    .nav {
        position: absolute;
        z-index: 1;
        left: -100%;
        top: 0px;
        width: 100%;
        background: black;
        height: 100vh;
        padding: 15px;
    }
}